.tpt-frontend {
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */ }
  .tpt-frontend .container {
    padding-right: 5px;
    padding-left: 5px;
    margin-right: auto;
    margin-left: auto; }
    .tpt-frontend .container:before, .tpt-frontend .container:after {
      display: table;
      content: " "; }
    .tpt-frontend .container:after {
      clear: both; }
    @media (min-width: 768px) {
      .tpt-frontend .container {
        width: 1024px; } }
    @media (min-width: 1024px) {
      .tpt-frontend .container {
        width: 1024px; } }
    @media (min-width: 1200px) {
      .tpt-frontend .container {
        width: 1024px; } }
  .tpt-frontend .container-fluid {
    padding-right: 5px;
    padding-left: 5px;
    margin-right: auto;
    margin-left: auto; }
    .tpt-frontend .container-fluid:before, .tpt-frontend .container-fluid:after {
      display: table;
      content: " "; }
    .tpt-frontend .container-fluid:after {
      clear: both; }
  .tpt-frontend .row {
    margin-right: -5px;
    margin-left: -5px; }
    .tpt-frontend .row:before, .tpt-frontend .row:after {
      display: table;
      content: " "; }
    .tpt-frontend .row:after {
      clear: both; }
  .tpt-frontend .row-no-gutters {
    margin-right: 0;
    margin-left: 0; }
    .tpt-frontend .row-no-gutters [class*="col-"] {
      padding-right: 0;
      padding-left: 0; }
  .tpt-frontend .col-xs-1, .tpt-frontend .col-sm-1, .tpt-frontend .col-md-1, .tpt-frontend .col-lg-1, .tpt-frontend .col-xs-2, .tpt-frontend .col-sm-2, .tpt-frontend .col-md-2, .tpt-frontend .col-lg-2, .tpt-frontend .col-xs-3, .tpt-frontend .col-sm-3, .tpt-frontend .col-md-3, .tpt-frontend .col-lg-3, .tpt-frontend .col-xs-4, .tpt-frontend .col-sm-4, .tpt-frontend .col-md-4, .tpt-frontend .col-lg-4, .tpt-frontend .col-xs-5, .tpt-frontend .col-sm-5, .tpt-frontend .col-md-5, .tpt-frontend .col-lg-5, .tpt-frontend .col-xs-6, .tpt-frontend .col-sm-6, .tpt-frontend .col-md-6, .tpt-frontend .col-lg-6, .tpt-frontend .col-xs-7, .tpt-frontend .col-sm-7, .tpt-frontend .col-md-7, .tpt-frontend .col-lg-7, .tpt-frontend .col-xs-8, .tpt-frontend .col-sm-8, .tpt-frontend .col-md-8, .tpt-frontend .col-lg-8, .tpt-frontend .col-xs-9, .tpt-frontend .col-sm-9, .tpt-frontend .col-md-9, .tpt-frontend .col-lg-9, .tpt-frontend .col-xs-10, .tpt-frontend .col-sm-10, .tpt-frontend .col-md-10, .tpt-frontend .col-lg-10, .tpt-frontend .col-xs-11, .tpt-frontend .col-sm-11, .tpt-frontend .col-md-11, .tpt-frontend .col-lg-11, .tpt-frontend .col-xs-12, .tpt-frontend .col-sm-12, .tpt-frontend .col-md-12, .tpt-frontend .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px; }
  .tpt-frontend .col-xs-1, .tpt-frontend .col-xs-2, .tpt-frontend .col-xs-3, .tpt-frontend .col-xs-4, .tpt-frontend .col-xs-5, .tpt-frontend .col-xs-6, .tpt-frontend .col-xs-7, .tpt-frontend .col-xs-8, .tpt-frontend .col-xs-9, .tpt-frontend .col-xs-10, .tpt-frontend .col-xs-11, .tpt-frontend .col-xs-12 {
    float: left; }
  .tpt-frontend .col-xs-1 {
    width: 8.33333%; }
  .tpt-frontend .col-xs-2 {
    width: 16.66667%; }
  .tpt-frontend .col-xs-3 {
    width: 25%; }
  .tpt-frontend .col-xs-4 {
    width: 33.33333%; }
  .tpt-frontend .col-xs-5 {
    width: 41.66667%; }
  .tpt-frontend .col-xs-6 {
    width: 50%; }
  .tpt-frontend .col-xs-7 {
    width: 58.33333%; }
  .tpt-frontend .col-xs-8 {
    width: 66.66667%; }
  .tpt-frontend .col-xs-9 {
    width: 75%; }
  .tpt-frontend .col-xs-10 {
    width: 83.33333%; }
  .tpt-frontend .col-xs-11 {
    width: 91.66667%; }
  .tpt-frontend .col-xs-12 {
    width: 100%; }
  .tpt-frontend .col-xs-pull-0 {
    right: auto; }
  .tpt-frontend .col-xs-pull-1 {
    right: 8.33333%; }
  .tpt-frontend .col-xs-pull-2 {
    right: 16.66667%; }
  .tpt-frontend .col-xs-pull-3 {
    right: 25%; }
  .tpt-frontend .col-xs-pull-4 {
    right: 33.33333%; }
  .tpt-frontend .col-xs-pull-5 {
    right: 41.66667%; }
  .tpt-frontend .col-xs-pull-6 {
    right: 50%; }
  .tpt-frontend .col-xs-pull-7 {
    right: 58.33333%; }
  .tpt-frontend .col-xs-pull-8 {
    right: 66.66667%; }
  .tpt-frontend .col-xs-pull-9 {
    right: 75%; }
  .tpt-frontend .col-xs-pull-10 {
    right: 83.33333%; }
  .tpt-frontend .col-xs-pull-11 {
    right: 91.66667%; }
  .tpt-frontend .col-xs-pull-12 {
    right: 100%; }
  .tpt-frontend .col-xs-push-0 {
    left: auto; }
  .tpt-frontend .col-xs-push-1 {
    left: 8.33333%; }
  .tpt-frontend .col-xs-push-2 {
    left: 16.66667%; }
  .tpt-frontend .col-xs-push-3 {
    left: 25%; }
  .tpt-frontend .col-xs-push-4 {
    left: 33.33333%; }
  .tpt-frontend .col-xs-push-5 {
    left: 41.66667%; }
  .tpt-frontend .col-xs-push-6 {
    left: 50%; }
  .tpt-frontend .col-xs-push-7 {
    left: 58.33333%; }
  .tpt-frontend .col-xs-push-8 {
    left: 66.66667%; }
  .tpt-frontend .col-xs-push-9 {
    left: 75%; }
  .tpt-frontend .col-xs-push-10 {
    left: 83.33333%; }
  .tpt-frontend .col-xs-push-11 {
    left: 91.66667%; }
  .tpt-frontend .col-xs-push-12 {
    left: 100%; }
  .tpt-frontend .col-xs-offset-0 {
    margin-left: 0%; }
  .tpt-frontend .col-xs-offset-1 {
    margin-left: 8.33333%; }
  .tpt-frontend .col-xs-offset-2 {
    margin-left: 16.66667%; }
  .tpt-frontend .col-xs-offset-3 {
    margin-left: 25%; }
  .tpt-frontend .col-xs-offset-4 {
    margin-left: 33.33333%; }
  .tpt-frontend .col-xs-offset-5 {
    margin-left: 41.66667%; }
  .tpt-frontend .col-xs-offset-6 {
    margin-left: 50%; }
  .tpt-frontend .col-xs-offset-7 {
    margin-left: 58.33333%; }
  .tpt-frontend .col-xs-offset-8 {
    margin-left: 66.66667%; }
  .tpt-frontend .col-xs-offset-9 {
    margin-left: 75%; }
  .tpt-frontend .col-xs-offset-10 {
    margin-left: 83.33333%; }
  .tpt-frontend .col-xs-offset-11 {
    margin-left: 91.66667%; }
  .tpt-frontend .col-xs-offset-12 {
    margin-left: 100%; }
  @media (min-width: 768px) {
    .tpt-frontend .col-sm-1, .tpt-frontend .col-sm-2, .tpt-frontend .col-sm-3, .tpt-frontend .col-sm-4, .tpt-frontend .col-sm-5, .tpt-frontend .col-sm-6, .tpt-frontend .col-sm-7, .tpt-frontend .col-sm-8, .tpt-frontend .col-sm-9, .tpt-frontend .col-sm-10, .tpt-frontend .col-sm-11, .tpt-frontend .col-sm-12 {
      float: left; }
    .tpt-frontend .col-sm-1 {
      width: 8.33333%; }
    .tpt-frontend .col-sm-2 {
      width: 16.66667%; }
    .tpt-frontend .col-sm-3 {
      width: 25%; }
    .tpt-frontend .col-sm-4 {
      width: 33.33333%; }
    .tpt-frontend .col-sm-5 {
      width: 41.66667%; }
    .tpt-frontend .col-sm-6 {
      width: 50%; }
    .tpt-frontend .col-sm-7 {
      width: 58.33333%; }
    .tpt-frontend .col-sm-8 {
      width: 66.66667%; }
    .tpt-frontend .col-sm-9 {
      width: 75%; }
    .tpt-frontend .col-sm-10 {
      width: 83.33333%; }
    .tpt-frontend .col-sm-11 {
      width: 91.66667%; }
    .tpt-frontend .col-sm-12 {
      width: 100%; }
    .tpt-frontend .col-sm-pull-0 {
      right: auto; }
    .tpt-frontend .col-sm-pull-1 {
      right: 8.33333%; }
    .tpt-frontend .col-sm-pull-2 {
      right: 16.66667%; }
    .tpt-frontend .col-sm-pull-3 {
      right: 25%; }
    .tpt-frontend .col-sm-pull-4 {
      right: 33.33333%; }
    .tpt-frontend .col-sm-pull-5 {
      right: 41.66667%; }
    .tpt-frontend .col-sm-pull-6 {
      right: 50%; }
    .tpt-frontend .col-sm-pull-7 {
      right: 58.33333%; }
    .tpt-frontend .col-sm-pull-8 {
      right: 66.66667%; }
    .tpt-frontend .col-sm-pull-9 {
      right: 75%; }
    .tpt-frontend .col-sm-pull-10 {
      right: 83.33333%; }
    .tpt-frontend .col-sm-pull-11 {
      right: 91.66667%; }
    .tpt-frontend .col-sm-pull-12 {
      right: 100%; }
    .tpt-frontend .col-sm-push-0 {
      left: auto; }
    .tpt-frontend .col-sm-push-1 {
      left: 8.33333%; }
    .tpt-frontend .col-sm-push-2 {
      left: 16.66667%; }
    .tpt-frontend .col-sm-push-3 {
      left: 25%; }
    .tpt-frontend .col-sm-push-4 {
      left: 33.33333%; }
    .tpt-frontend .col-sm-push-5 {
      left: 41.66667%; }
    .tpt-frontend .col-sm-push-6 {
      left: 50%; }
    .tpt-frontend .col-sm-push-7 {
      left: 58.33333%; }
    .tpt-frontend .col-sm-push-8 {
      left: 66.66667%; }
    .tpt-frontend .col-sm-push-9 {
      left: 75%; }
    .tpt-frontend .col-sm-push-10 {
      left: 83.33333%; }
    .tpt-frontend .col-sm-push-11 {
      left: 91.66667%; }
    .tpt-frontend .col-sm-push-12 {
      left: 100%; }
    .tpt-frontend .col-sm-offset-0 {
      margin-left: 0%; }
    .tpt-frontend .col-sm-offset-1 {
      margin-left: 8.33333%; }
    .tpt-frontend .col-sm-offset-2 {
      margin-left: 16.66667%; }
    .tpt-frontend .col-sm-offset-3 {
      margin-left: 25%; }
    .tpt-frontend .col-sm-offset-4 {
      margin-left: 33.33333%; }
    .tpt-frontend .col-sm-offset-5 {
      margin-left: 41.66667%; }
    .tpt-frontend .col-sm-offset-6 {
      margin-left: 50%; }
    .tpt-frontend .col-sm-offset-7 {
      margin-left: 58.33333%; }
    .tpt-frontend .col-sm-offset-8 {
      margin-left: 66.66667%; }
    .tpt-frontend .col-sm-offset-9 {
      margin-left: 75%; }
    .tpt-frontend .col-sm-offset-10 {
      margin-left: 83.33333%; }
    .tpt-frontend .col-sm-offset-11 {
      margin-left: 91.66667%; }
    .tpt-frontend .col-sm-offset-12 {
      margin-left: 100%; } }
  @media (min-width: 1024px) {
    .tpt-frontend .col-md-1, .tpt-frontend .col-md-2, .tpt-frontend .col-md-3, .tpt-frontend .col-md-4, .tpt-frontend .col-md-5, .tpt-frontend .col-md-6, .tpt-frontend .col-md-7, .tpt-frontend .col-md-8, .tpt-frontend .col-md-9, .tpt-frontend .col-md-10, .tpt-frontend .col-md-11, .tpt-frontend .col-md-12 {
      float: left; }
    .tpt-frontend .col-md-1 {
      width: 8.33333%; }
    .tpt-frontend .col-md-2 {
      width: 16.66667%; }
    .tpt-frontend .col-md-3 {
      width: 25%; }
    .tpt-frontend .col-md-4 {
      width: 33.33333%; }
    .tpt-frontend .col-md-5 {
      width: 41.66667%; }
    .tpt-frontend .col-md-6 {
      width: 50%; }
    .tpt-frontend .col-md-7 {
      width: 58.33333%; }
    .tpt-frontend .col-md-8 {
      width: 66.66667%; }
    .tpt-frontend .col-md-9 {
      width: 75%; }
    .tpt-frontend .col-md-10 {
      width: 83.33333%; }
    .tpt-frontend .col-md-11 {
      width: 91.66667%; }
    .tpt-frontend .col-md-12 {
      width: 100%; }
    .tpt-frontend .col-md-pull-0 {
      right: auto; }
    .tpt-frontend .col-md-pull-1 {
      right: 8.33333%; }
    .tpt-frontend .col-md-pull-2 {
      right: 16.66667%; }
    .tpt-frontend .col-md-pull-3 {
      right: 25%; }
    .tpt-frontend .col-md-pull-4 {
      right: 33.33333%; }
    .tpt-frontend .col-md-pull-5 {
      right: 41.66667%; }
    .tpt-frontend .col-md-pull-6 {
      right: 50%; }
    .tpt-frontend .col-md-pull-7 {
      right: 58.33333%; }
    .tpt-frontend .col-md-pull-8 {
      right: 66.66667%; }
    .tpt-frontend .col-md-pull-9 {
      right: 75%; }
    .tpt-frontend .col-md-pull-10 {
      right: 83.33333%; }
    .tpt-frontend .col-md-pull-11 {
      right: 91.66667%; }
    .tpt-frontend .col-md-pull-12 {
      right: 100%; }
    .tpt-frontend .col-md-push-0 {
      left: auto; }
    .tpt-frontend .col-md-push-1 {
      left: 8.33333%; }
    .tpt-frontend .col-md-push-2 {
      left: 16.66667%; }
    .tpt-frontend .col-md-push-3 {
      left: 25%; }
    .tpt-frontend .col-md-push-4 {
      left: 33.33333%; }
    .tpt-frontend .col-md-push-5 {
      left: 41.66667%; }
    .tpt-frontend .col-md-push-6 {
      left: 50%; }
    .tpt-frontend .col-md-push-7 {
      left: 58.33333%; }
    .tpt-frontend .col-md-push-8 {
      left: 66.66667%; }
    .tpt-frontend .col-md-push-9 {
      left: 75%; }
    .tpt-frontend .col-md-push-10 {
      left: 83.33333%; }
    .tpt-frontend .col-md-push-11 {
      left: 91.66667%; }
    .tpt-frontend .col-md-push-12 {
      left: 100%; }
    .tpt-frontend .col-md-offset-0 {
      margin-left: 0%; }
    .tpt-frontend .col-md-offset-1 {
      margin-left: 8.33333%; }
    .tpt-frontend .col-md-offset-2 {
      margin-left: 16.66667%; }
    .tpt-frontend .col-md-offset-3 {
      margin-left: 25%; }
    .tpt-frontend .col-md-offset-4 {
      margin-left: 33.33333%; }
    .tpt-frontend .col-md-offset-5 {
      margin-left: 41.66667%; }
    .tpt-frontend .col-md-offset-6 {
      margin-left: 50%; }
    .tpt-frontend .col-md-offset-7 {
      margin-left: 58.33333%; }
    .tpt-frontend .col-md-offset-8 {
      margin-left: 66.66667%; }
    .tpt-frontend .col-md-offset-9 {
      margin-left: 75%; }
    .tpt-frontend .col-md-offset-10 {
      margin-left: 83.33333%; }
    .tpt-frontend .col-md-offset-11 {
      margin-left: 91.66667%; }
    .tpt-frontend .col-md-offset-12 {
      margin-left: 100%; } }
  @media (min-width: 1200px) {
    .tpt-frontend .col-lg-1, .tpt-frontend .col-lg-2, .tpt-frontend .col-lg-3, .tpt-frontend .col-lg-4, .tpt-frontend .col-lg-5, .tpt-frontend .col-lg-6, .tpt-frontend .col-lg-7, .tpt-frontend .col-lg-8, .tpt-frontend .col-lg-9, .tpt-frontend .col-lg-10, .tpt-frontend .col-lg-11, .tpt-frontend .col-lg-12 {
      float: left; }
    .tpt-frontend .col-lg-1 {
      width: 8.33333%; }
    .tpt-frontend .col-lg-2 {
      width: 16.66667%; }
    .tpt-frontend .col-lg-3 {
      width: 25%; }
    .tpt-frontend .col-lg-4 {
      width: 33.33333%; }
    .tpt-frontend .col-lg-5 {
      width: 41.66667%; }
    .tpt-frontend .col-lg-6 {
      width: 50%; }
    .tpt-frontend .col-lg-7 {
      width: 58.33333%; }
    .tpt-frontend .col-lg-8 {
      width: 66.66667%; }
    .tpt-frontend .col-lg-9 {
      width: 75%; }
    .tpt-frontend .col-lg-10 {
      width: 83.33333%; }
    .tpt-frontend .col-lg-11 {
      width: 91.66667%; }
    .tpt-frontend .col-lg-12 {
      width: 100%; }
    .tpt-frontend .col-lg-pull-0 {
      right: auto; }
    .tpt-frontend .col-lg-pull-1 {
      right: 8.33333%; }
    .tpt-frontend .col-lg-pull-2 {
      right: 16.66667%; }
    .tpt-frontend .col-lg-pull-3 {
      right: 25%; }
    .tpt-frontend .col-lg-pull-4 {
      right: 33.33333%; }
    .tpt-frontend .col-lg-pull-5 {
      right: 41.66667%; }
    .tpt-frontend .col-lg-pull-6 {
      right: 50%; }
    .tpt-frontend .col-lg-pull-7 {
      right: 58.33333%; }
    .tpt-frontend .col-lg-pull-8 {
      right: 66.66667%; }
    .tpt-frontend .col-lg-pull-9 {
      right: 75%; }
    .tpt-frontend .col-lg-pull-10 {
      right: 83.33333%; }
    .tpt-frontend .col-lg-pull-11 {
      right: 91.66667%; }
    .tpt-frontend .col-lg-pull-12 {
      right: 100%; }
    .tpt-frontend .col-lg-push-0 {
      left: auto; }
    .tpt-frontend .col-lg-push-1 {
      left: 8.33333%; }
    .tpt-frontend .col-lg-push-2 {
      left: 16.66667%; }
    .tpt-frontend .col-lg-push-3 {
      left: 25%; }
    .tpt-frontend .col-lg-push-4 {
      left: 33.33333%; }
    .tpt-frontend .col-lg-push-5 {
      left: 41.66667%; }
    .tpt-frontend .col-lg-push-6 {
      left: 50%; }
    .tpt-frontend .col-lg-push-7 {
      left: 58.33333%; }
    .tpt-frontend .col-lg-push-8 {
      left: 66.66667%; }
    .tpt-frontend .col-lg-push-9 {
      left: 75%; }
    .tpt-frontend .col-lg-push-10 {
      left: 83.33333%; }
    .tpt-frontend .col-lg-push-11 {
      left: 91.66667%; }
    .tpt-frontend .col-lg-push-12 {
      left: 100%; }
    .tpt-frontend .col-lg-offset-0 {
      margin-left: 0%; }
    .tpt-frontend .col-lg-offset-1 {
      margin-left: 8.33333%; }
    .tpt-frontend .col-lg-offset-2 {
      margin-left: 16.66667%; }
    .tpt-frontend .col-lg-offset-3 {
      margin-left: 25%; }
    .tpt-frontend .col-lg-offset-4 {
      margin-left: 33.33333%; }
    .tpt-frontend .col-lg-offset-5 {
      margin-left: 41.66667%; }
    .tpt-frontend .col-lg-offset-6 {
      margin-left: 50%; }
    .tpt-frontend .col-lg-offset-7 {
      margin-left: 58.33333%; }
    .tpt-frontend .col-lg-offset-8 {
      margin-left: 66.66667%; }
    .tpt-frontend .col-lg-offset-9 {
      margin-left: 75%; }
    .tpt-frontend .col-lg-offset-10 {
      margin-left: 83.33333%; }
    .tpt-frontend .col-lg-offset-11 {
      margin-left: 91.66667%; }
    .tpt-frontend .col-lg-offset-12 {
      margin-left: 100%; } }

@-ms-viewport {
  width: device-width; }
  .tpt-frontend .visible-xs {
    display: none !important; }
  .tpt-frontend .visible-sm {
    display: none !important; }
  .tpt-frontend .visible-md {
    display: none !important; }
  .tpt-frontend .visible-lg {
    display: none !important; }
  .tpt-frontend .visible-xs-block,
  .tpt-frontend .visible-xs-inline,
  .tpt-frontend .visible-xs-inline-block,
  .tpt-frontend .visible-sm-block,
  .tpt-frontend .visible-sm-inline,
  .tpt-frontend .visible-sm-inline-block,
  .tpt-frontend .visible-md-block,
  .tpt-frontend .visible-md-inline,
  .tpt-frontend .visible-md-inline-block,
  .tpt-frontend .visible-lg-block,
  .tpt-frontend .visible-lg-inline,
  .tpt-frontend .visible-lg-inline-block {
    display: none !important; }
  @media (max-width: 767px) {
    .tpt-frontend .visible-xs {
      display: block !important; }
    .tpt-frontend table.visible-xs {
      display: table !important; }
    .tpt-frontend tr.visible-xs {
      display: table-row !important; }
    .tpt-frontend th.visible-xs,
    .tpt-frontend td.visible-xs {
      display: table-cell !important; } }
  @media (max-width: 767px) {
    .tpt-frontend .visible-xs-block {
      display: block !important; } }
  @media (max-width: 767px) {
    .tpt-frontend .visible-xs-inline {
      display: inline !important; } }
  @media (max-width: 767px) {
    .tpt-frontend .visible-xs-inline-block {
      display: inline-block !important; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .tpt-frontend .visible-sm {
      display: block !important; }
    .tpt-frontend table.visible-sm {
      display: table !important; }
    .tpt-frontend tr.visible-sm {
      display: table-row !important; }
    .tpt-frontend th.visible-sm,
    .tpt-frontend td.visible-sm {
      display: table-cell !important; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .tpt-frontend .visible-sm-block {
      display: block !important; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .tpt-frontend .visible-sm-inline {
      display: inline !important; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .tpt-frontend .visible-sm-inline-block {
      display: inline-block !important; } }
  @media (min-width: 1024px) and (max-width: 1199px) {
    .tpt-frontend .visible-md {
      display: block !important; }
    .tpt-frontend table.visible-md {
      display: table !important; }
    .tpt-frontend tr.visible-md {
      display: table-row !important; }
    .tpt-frontend th.visible-md,
    .tpt-frontend td.visible-md {
      display: table-cell !important; } }
  @media (min-width: 1024px) and (max-width: 1199px) {
    .tpt-frontend .visible-md-block {
      display: block !important; } }
  @media (min-width: 1024px) and (max-width: 1199px) {
    .tpt-frontend .visible-md-inline {
      display: inline !important; } }
  @media (min-width: 1024px) and (max-width: 1199px) {
    .tpt-frontend .visible-md-inline-block {
      display: inline-block !important; } }
  @media (min-width: 1200px) {
    .tpt-frontend .visible-lg {
      display: block !important; }
    .tpt-frontend table.visible-lg {
      display: table !important; }
    .tpt-frontend tr.visible-lg {
      display: table-row !important; }
    .tpt-frontend th.visible-lg,
    .tpt-frontend td.visible-lg {
      display: table-cell !important; } }
  @media (min-width: 1200px) {
    .tpt-frontend .visible-lg-block {
      display: block !important; } }
  @media (min-width: 1200px) {
    .tpt-frontend .visible-lg-inline {
      display: inline !important; } }
  @media (min-width: 1200px) {
    .tpt-frontend .visible-lg-inline-block {
      display: inline-block !important; } }
  @media (max-width: 767px) {
    .tpt-frontend .hidden-xs {
      display: none !important; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .tpt-frontend .hidden-sm {
      display: none !important; } }
  @media (min-width: 1024px) and (max-width: 1199px) {
    .tpt-frontend .hidden-md {
      display: none !important; } }
  @media (min-width: 1200px) {
    .tpt-frontend .hidden-lg {
      display: none !important; } }
  .tpt-frontend .visible-print {
    display: none !important; }
  @media print {
    .tpt-frontend .visible-print {
      display: block !important; }
    .tpt-frontend table.visible-print {
      display: table !important; }
    .tpt-frontend tr.visible-print {
      display: table-row !important; }
    .tpt-frontend th.visible-print,
    .tpt-frontend td.visible-print {
      display: table-cell !important; } }
  .tpt-frontend .visible-print-block {
    display: none !important; }
    @media print {
      .tpt-frontend .visible-print-block {
        display: block !important; } }
  .tpt-frontend .visible-print-inline {
    display: none !important; }
    @media print {
      .tpt-frontend .visible-print-inline {
        display: inline !important; } }
  .tpt-frontend .visible-print-inline-block {
    display: none !important; }
    @media print {
      .tpt-frontend .visible-print-inline-block {
        display: inline-block !important; } }
  @media print {
    .tpt-frontend .hidden-print {
      display: none !important; } }
  .tpt-frontend div.row.row-middle > div[class^='col-'],
  .tpt-frontend div.row.row-middle > div[class*=' col-'] {
    display: inline-block;
    vertical-align: middle;
    float: none; }

